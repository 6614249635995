import Image from "next/image";
import { Dispatch, useContext, useEffect, useState, ReactElement } from "react";
import { UsOddsBookmaker } from "@/types/api/betting";
import { bannerLogoUrlFromName } from "@/lib/media";
import { isoDateStringToDayNameAndTime } from "@/lib/date";
import { getUsBettingOdds } from "@/lib/http/betting";
import { gaEvent, betslipClickoutEventParams } from "@/lib/gtag";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import {
  BetSlipActions,
  BetSlipState,
  CommonAndEventIdPair,
} from "@/types/betslip";
import { SelectionInterface } from "@/types/api/selections";
import {
  commonAndEventIdsInArray,
  removeCommonAndEventIdFromArray,
  removeFromBetSlipEventParams,
  addToBetSlipEventParams,
} from "@/lib/betslip";
import {
  BetSlipStateContext,
  BetSlipDispatchContext,
} from "@/contexts/betSlipContext";
import { getFormattedOdds } from "@/lib/odds";
import { ConditionalWrapper } from "@/components/ConditionalWrapper/ConditionalWrapper";
import { FP_BRAND } from "@/constants/brands";
import { BookieOffer } from "../BookieOffer/BookieOffer";
import AngleUpIcon from "../icons/AngleUpIcon";
import AngleDownIcon from "../icons/AngleDownIcon";
import { TimesIcon } from "../icons/TimesIcon";
import { BookieReturns } from "../BookieReturns/BookieReturns";
import { ArrowRight } from "../icons/ArrowRight";
import styles from "./FpBookieDisplay.module.scss";

interface FpBookieDisplayProps {
  bookmaker: UsOddsBookmaker;
  stake: number;
  bestOdds?: boolean;
  isOpen: boolean;
}

export const FpBookieDisplay = ({
  bookmaker,
  stake,
  bestOdds = false,
  isOpen,
}: FpBookieDisplayProps) => {
  const [locale] = useLocaleFromRouter();
  const { oddsFormat } = useContext(BetSlipStateContext) as BetSlipState;
  const dispatch = useContext(
    BetSlipDispatchContext
  ) as Dispatch<BetSlipActions>;
  const [toggleSelections, setToggleSelections] = useState(bestOdds);
  const [disabledSelections, setDisabledSelections] = useState<
    CommonAndEventIdPair[]
  >([]);
  const [odds, setOdds] = useState({
    odds: bookmaker.odds,
    oddsAmerican: bookmaker.oddsAmerican,
    oddsDecimal: bookmaker.oddsDecimal,
  });
  const localStorageName = `${bookmaker.bookmaker}-disabledCommonAndEventIdPairs}`;

  const handleToggleSelections = () => {
    setToggleSelections(!toggleSelections);
  };

  useEffect(() => {
    // update the bestOdds state when the prop changes
    // this is mainly for when new selections have been added/removed from the betslip
    // resulting in the bookmakers order being changed, we always want the first
    // bookmaker in the list to show the selections by default
    setToggleSelections(bestOdds);
  }, [bestOdds]);

  const updateDisabledSelections = (
    {
      commonID,
      eventId,
    }: {
      commonID: UsOddsBookmaker["selections"][0]["commonID"];
      eventId: UsOddsBookmaker["selections"][0]["event"]["id"];
    },
    selection: SelectionInterface
  ) => {
    const commonAndEventIdPair = {
      commonID,
      eventId,
    };
    const selectionDisabled = commonAndEventIdsInArray(
      disabledSelections,
      commonAndEventIdPair
    );

    let disabledCommonAndEventIdPairs: CommonAndEventIdPair[] = [];

    if (selectionDisabled) {
      disabledCommonAndEventIdPairs = removeCommonAndEventIdFromArray(
        disabledSelections,
        commonAndEventIdPair
      );
      // GA Event
      const gaEventParams = addToBetSlipEventParams(selection, null);
      gaEvent("add_to_betslip", gaEventParams);
    } else {
      // Find the active selection and add to disabledSelections
      const activeSelection = bookmaker.selections.find(
        (selection) =>
          selection.commonID === commonID && selection.event.id === eventId
      );
      disabledCommonAndEventIdPairs = activeSelection
        ? [
            ...disabledSelections,
            {
              commonID: activeSelection.commonID,
              eventId: activeSelection.event.id,
            },
          ]
        : [...disabledSelections];
      // GA Event
      const gaEventParams = removeFromBetSlipEventParams(selection);
      gaEvent("remove_from_betslip", gaEventParams);
    }

    setDisabledSelections(disabledCommonAndEventIdPairs);

    localStorage.setItem(
      localStorageName,
      JSON.stringify({
        disabledCommonAndEventIdPairs,
      })
    );
  };

  const fetchData = async () => {
    const activeSelections = bookmaker.selections.filter((selection) => {
      const disabled = disabledSelections.some(
        ({ commonID, eventId }) =>
          selection.commonID === commonID && selection.event.id === eventId
      );
      return !disabled;
    });
    const activeCommonAndEventIdPairsForBookie = activeSelections.map(
      (selection) => ({
        commonID: selection.commonID,
        eventId: selection.event.id,
      })
    );

    if (activeCommonAndEventIdPairsForBookie.length === 0) {
      setOdds({ odds: "0", oddsAmerican: "0", oddsDecimal: 0 });
    } else {
      const { bookmakers } = await getUsBettingOdds(
        activeCommonAndEventIdPairsForBookie,
        locale
      );

      const matchingBookmaker = bookmakers.find(
        (bookie) => bookie.bookmaker === bookmaker.bookmaker
      );

      if (matchingBookmaker) {
        setOdds({
          odds: matchingBookmaker.odds,
          oddsAmerican: matchingBookmaker.oddsAmerican,
          oddsDecimal: matchingBookmaker.oddsDecimal,
        });
      }
    }
  };

  const delWrapper = (children: ReactElement) => (
    <s className={styles.strikethrough} data-testid="delWrapper">
      {children}
    </s>
  );

  const gaEventParams = betslipClickoutEventParams(
    bookmaker.bookmaker,
    bookmaker.selections,
    bookmaker.oddsAmerican
  );
  const gaBetslipClickoutEvent = (): void => {
    gaEvent("betslip_clickout", gaEventParams);
  };

  useEffect(() => {
    const localStorageData = localStorage.getItem(localStorageName);
    if (localStorageData) {
      const { disabledCommonAndEventIdPairs } = JSON.parse(localStorageData);
      setDisabledSelections(disabledCommonAndEventIdPairs);
    }
  }, [localStorageName]);

  useEffect(() => {
    // We only want to fetch the data if the bet slip is open and there are disabled selections
    if (isOpen && disabledSelections.length > 0) {
      fetchData();
    }

    if (disabledSelections.length === 0) {
      setOdds({
        odds: bookmaker.odds,
        oddsAmerican: bookmaker.oddsAmerican,
        oddsDecimal: bookmaker.oddsDecimal,
      });
    }
    // We only want to run when disabled selections changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledSelections]);

  useEffect(() => {
    if (isOpen) {
      // All selections are active so we can use the bookmaker odds
      if (disabledSelections.length === 0) {
        setOdds({
          odds: bookmaker.odds,
          oddsAmerican: bookmaker.oddsAmerican,
          oddsDecimal: bookmaker.oddsDecimal,
        });
      }

      // We need to fetch the odds for the disabled selections only
      if (disabledSelections.length > 0) {
        fetchData();
      }
    }
    // We only want to run when the bookmaker changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmaker]);

  return (
    <div className={styles.bookmaker}>
      <div className={styles.bookmakerHeader} key={bookmaker.bookmaker}>
        <div>
          <Image
            src={bannerLogoUrlFromName(bookmaker.bookmaker)}
            alt={`${bookmaker.bookmaker} logo`}
            width={32}
            height={32}
          />
          <span data-testid="odds">
            {odds.oddsAmerican === "0"
              ? "No Selections Available"
              : getFormattedOdds(odds, oddsFormat)}
          </span>
        </div>
        {bestOdds && (
          <BookieOffer offer="bestodds" bookmaker={bookmaker.bookmaker} />
        )}
        <a
          // app and location appended to populate supported bookmakers bet slips
          // at this stage `stake` isn't supported so leaving static with the value in `betslipURL`
          href={`${
            bookmaker.betslipURL
          }&app=${FP_BRAND}&location=${locale.toUpperCase()}`}
          onClick={() => {
            gaBetslipClickoutEvent();
            dispatch({
              type: "CLEAR",
            });
          }}
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.circle}>
            <ArrowRight fill="none" className={styles.arrowRight} />
          </div>
        </a>
      </div>
      <div
        className={`${styles.returnsAndSelectionToggle} ${
          toggleSelections ? styles.expanded : ""
        }`}
      >
        <BookieReturns stake={stake} oddsDecimal={odds.oddsDecimal} />
        <button
          onClick={handleToggleSelections}
          className={styles.selectionsToggle}
        >
          <span>{toggleSelections ? "Hide" : "Show"} selections</span>
          {toggleSelections ? <AngleUpIcon /> : <AngleDownIcon />}
        </button>
      </div>
      {toggleSelections
        ? bookmaker.selections.map((selection) => {
            const selectionDescription = `${
              selection.selectionName
            } @ ${getFormattedOdds(
              {
                odds: selection.odds,
                oddsDecimal: selection.oddsDecimal,
                oddsAmerican: selection.oddsAmerican,
              },
              oddsFormat
            )}`;
            const selectionDisabled = commonAndEventIdsInArray(
              disabledSelections,
              { commonID: selection.commonID, eventId: selection.event.id }
            );

            return (
              <div
                className={styles.selection}
                key={selection.id}
                data-testid={`selection-${selection.id}`}
              >
                <ConditionalWrapper
                  wrapper={delWrapper}
                  condition={selectionDisabled}
                >
                  <div>
                    <p className={styles.selectionDescription}>
                      {selectionDescription}
                    </p>
                    <p className={styles.selectionMeta}>
                      {selection.marketName}
                    </p>
                    <p className={styles.selectionMeta}>
                      {selection.event.description}
                    </p>
                    <p className={styles.selectionMeta}>
                      {isoDateStringToDayNameAndTime(selection.event.date)}
                    </p>
                  </div>
                </ConditionalWrapper>
                <button
                  className={
                    selectionDisabled
                      ? styles["selectionActionButton-isDisabled"]
                      : styles.selectionActionButton
                  }
                  onClick={() =>
                    updateDisabledSelections(
                      {
                        commonID: selection.commonID,
                        eventId: selection.event.id,
                      },
                      selection
                    )
                  }
                >
                  {selectionDisabled
                    ? `Enable ${selectionDescription}`
                    : `Disable ${selectionDescription}`}
                  <TimesIcon />
                </button>
              </div>
            );
          })
        : null}
    </div>
  );
};
