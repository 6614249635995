import { CommonAndEventIdPair } from "@/types/betslip";
import { USOddsResponse } from "@/types/api/betting";
import { commonAndEventIdsToQueryString } from "../betslip";
import { getActiveBrand } from "../brand";
import { getDataFromPathAndQueryParams } from "./helpers";

export const getUsBettingOdds = async (
  commonAndSelectionIds: CommonAndEventIdPair[],
  locale: string
): Promise<USOddsResponse> => {
  if (!commonAndSelectionIds?.length)
    return Promise.resolve({
      code: 0,
      bookmakers: [],
    });

  const brand = getActiveBrand();
  const commonIdAndSelectionString = commonAndEventIdsToQueryString(
    commonAndSelectionIds
  ).join(",");
  const queryParams = {
    selections: commonIdAndSelectionString,
    location: locale.toUpperCase(),
    brand,
  };
  const path = "betting/us-odds";

  return await getDataFromPathAndQueryParams({
    path,
    queryParams,
    isOddsPlatformRequest: true,
  });
};
