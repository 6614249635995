import { useContext, useEffect, useState, Dispatch } from "react";
import { Bookies } from "@/components/Bookies/Bookies";
import { FpBookieDisplay } from "@/components/FpBookieDisplay/FpBookieDisplay";
import {
  BetSlipStateContext,
  BetSlipDispatchContext,
} from "@/contexts/betSlipContext";
import { BetSlipState, BetSlipActions } from "@/types/betslip";
import { useLocaleFromRouter } from "@/hooks/LocationFromRouter";
import { getUsBettingOdds } from "@/lib/http/betting";
import { UsOddsBookmaker } from "@/types/api/betting";

export interface FpBookiesRendererProps {
  stakeValue: number;
  setStakeValue: (value: string) => void;
  stakeInput: "button" | "input";
  setStakeInput: (value: "button" | "input") => void;
}

export const FpBookiesRenderer: React.FC<FpBookiesRendererProps> = ({
  stakeValue,
  setStakeValue,
  stakeInput,
  setStakeInput,
}) => {
  const { activeCommonAndEventIdPairs, isOpen } = useContext(
    BetSlipStateContext
  ) as BetSlipState;
  const [locale] = useLocaleFromRouter();
  const [bookmakers, setBookmakers] = useState<UsOddsBookmaker[]>([]);
  const dispatch = useContext(
    BetSlipDispatchContext
  ) as Dispatch<BetSlipActions>;

  useEffect(() => {
    const fetchData = async () => {
      const { bookmakers } = await getUsBettingOdds(
        activeCommonAndEventIdPairs,
        locale
      );

      if (bookmakers) {
        setBookmakers(bookmakers);

        // Set bookmakers in betslipContext here so we can use the odds data in the BettingIframe to
        // send a message to the smartpicks iframe to be received to display the odds on the floating betslip.
        dispatch({
          type: "SET_BEST_ODDS",
          bestOdds: {
            odds: bookmakers[0]?.odds,
            oddsAmerican: bookmakers[0]?.oddsAmerican,
            oddsDecimal: bookmakers[0]?.oddsDecimal,
          },
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCommonAndEventIdPairs, isOpen, locale]);

  return (
    <Bookies
      stakeValue={`${stakeValue}`}
      setStakeValue={setStakeValue}
      stakeInput={stakeInput}
      setStakeInput={setStakeInput}
      selectionCount={activeCommonAndEventIdPairs.length}
    >
      <>
        {bookmakers.map((bookmaker, i) => (
          <FpBookieDisplay
            key={bookmaker.bookmaker}
            bookmaker={bookmaker}
            stake={stakeValue}
            bestOdds={i === 0}
            isOpen={isOpen}
          />
        ))}
      </>
    </Bookies>
  );
};
